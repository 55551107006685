
import { useEffect, useState } from "react";

import axios from "axios";
import { useParams } from "react-router-dom";
import { BASE_BACKEND } from "../../../App";
import Loading from "../../Loading";

function FaxInfo({showContent}){
    const japanTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Tokyo',hour12: false });
    const [japanMonth, japanDay, japanYear, japanHour, japanMinute] = japanTime.split(/\/|, |:|\s/);
    
    var year = japanYear;
    var thismonth = ("0" + japanMonth).slice(-2);
    var day = ("0" + japanDay).slice(-2);
    const [ isloading, setIsloading ] = useState(true);
    const [today, setToday] = useState(year + "-" + thismonth + "-" + day);
    const [ restaurantlists, setRestaurantlists ] = useState("");
    const [ company_name, setCompany_name] = useState("");
    const [ address, setAddress] = useState("");
    const [ presenter_name, setPresenter_name] = useState("");
    const [ orderhello, setOrderhello] = useState("");
    const [ orderdetail, setOrderdetail] = useState("");
    const showFaxinfo = (e) =>{
        setToday(e.target.value);
        showfaxdetail(e.target.value);
    }
    const showfaxdetail = (date) =>{
            const token = localStorage.getItem("token");
            const header = {
                headers:{
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json",
                    "Accept":"application/json"
                }
            }
            const data ={
                "yearMonth" : date.slice(0,7),
                "day" : date.slice(8,10)
            }
            const url1 = BASE_BACKEND + "api/orderhistory/byrestaurant";
            axios.post(url1,data,header)
            .then(res=>{
                console.log(typeof(res.data));
                console.log(Object.keys(res.data).length);
                if(res.data){
                    setOrderdetail(res.data);
                    
                }
                
            }).catch(err=>console.log(err.response.data));
    }
    useEffect(()=>{
        showContent("faxinfo");
            const token = localStorage.getItem("token");
            const header = {
                headers:{
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json",
                    "Accept":"application/json"
                }
            }
            const url1 = BASE_BACKEND + "api/bentos";
            axios.get(url1,header)
            .then(res=>{
                console.log(res.data);
                if(res.data.data){
                    setRestaurantlists(res.data.data[0]);
                    
                }
                
            }).catch(err=>console.log(err.response.data));
            const url = BASE_BACKEND + "api/faxinfolists";
            axios.get(url,header)
            .then(res=>{
                console.log(res.data);
                if(res.data[0]){
                    const faxdata = res.data[0];
                    setCompany_name(faxdata.company_name);
                    setAddress(faxdata.address);
                    setPresenter_name(faxdata.presenter_name);
                    setOrderhello(faxdata.faxcontent)
                }
                setIsloading(false);
                
            }).catch(err=>console.log(err.response.data));
            showfaxdetail(today);
    },[]);
    return (
        <div>
            
            <div>
                <div>
                    <div className="d-flex justify-content-center">
                        <div className="switch-title switch-border">FAX注文ひな型</div>
                    </div>
                </div>
            </div>
            <div className="white-box p-4 position-relative">
            {isloading && <Loading />}
                <div className="d-flex flex-column justify-content-center">
                            <div className="mb-3 w-75 row">
                                <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">日付</label>
                                <div className="col-sm-6">
                                    <input type="date" value={today} onChange={showFaxinfo} className="form-control"   />
                                </div>
                            </div>
                            <div className="mb-3 w-75 row">
                                <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">現場名</label>
                                <div className="col-sm-6">
                                    <input disabled value={company_name} type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="mb-3 w-75 row">
                                <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">住所</label>
                                <div className="col-sm-6">
                                    <input disabled value={address} type="text" className="form-control"  />
                                </div>
                            </div>
                            <div className="mb-3 w-75 row">
                                <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">発注担当者</label>
                                <div className="col-sm-6">
                                    <input disabled value={presenter_name} type="text" className="form-control" step=""  />
                                </div>
                            </div>
                            <div className="mb-3 w-75 row">
                                <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">発注内容</label>
                                <div className="col-sm-6">
                                    <div className="show-orderdetail overflow-auto">
                                    <p className="order-text">{orderhello}</p>
                                    
                                        {Object.keys(orderdetail).length > 0 && orderdetail.map((order)=>(
                                            <div className="d-flex">
                                                <div className="order-text">{order.subcompany} : </div>
                                                <div className="ms-3">
                                                    {order.orderdetail.length >0 && order.orderdetail.map((detail)=>(
                                                        <p key={detail.id} className="order-text" >{detail.menu.title} : {detail.total}個</p>
                                                    ))}
                                                </div>
                                            </div>
                                        ))      }
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 w-75 row">
                                <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">注文先</label>
                                <div className="col-sm-6">
                                    <input disabled value={restaurantlists && restaurantlists.name} type="text" className="form-control" step=""  />
                                    
                                </div>
                            </div>
                            
                            
                        </div>
                        
                    
            </div>
        </div>
        
    );
}

export default FaxInfo;