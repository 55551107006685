import headlogo from "../../../assets/image/admin/header-logo.svg"
import avatar from "../../../assets/image/admin/admin.png"

function AdminFooter(){
    return(
        <div className="admin-footer d-flex justify-content-center align-items-center">
            <div className="text-white">
            © 2023 Questar, All Rights Reserved
            </div>
        </div>
    );
}

export default AdminFooter;