import { useState, useEffect, useContext } from "react";
import lunch from "../../../assets/image/lunch.png"

import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_BACKEND } from "../../../App";

function ClientUnderLunchDetail(){
    
    const [menu, setMenu] = useState();
    const [subcompanylists, setSubcompanylists] = useState();
    const [subcompany, setSubcompany] = useState();
    const [subcompanyname, setSubcompanyname] = useState();
    const [clientuserlists, setClientuserlists] = useState();
    const [clientuser, setClientuser] = useState();
    const [clientusername, setClientusername] = useState();

    const [orderdisable , setOrderdisable] = useState(true);
    const { company, lunch_id } = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        axios.get(BASE_BACKEND + "api/lunchmenulists/" + lunch_id, 
            {
            headers:{
                "Content-Type": "application/json",
                "Accept":"application/json"
            }}).then(response=>{
                let temp_menus = response.data.data;
                console.log(temp_menus);
                setMenu(temp_menus);
            }).catch(error=>{
                console.log(error);
            });
        axios.get(BASE_BACKEND + "api/" + company + "/subcompanylists", 
            {
            headers:{
                "Content-Type": "application/json",
                "Accept":"application/json"
            }}).then(response=>{
                let temp_menus = response.data.data;
                console.log(response);
                setSubcompanylists(temp_menus);
            }).catch(error=>{
                console.log(error);
            });
            
    },[]);

    const handleChangeSubcompany = (event)=>{
        let selected_subcompany = event.target.value;
        setSubcompany(selected_subcompany);
        setSubcompanyname(event.target.options[event.target.selectedIndex].text);
        if(selected_subcompany === "default"){
            
        }else{
            axios.post(BASE_BACKEND + "api/" + company + "/clientuserlists"
            ,{
                "subcompany" : selected_subcompany
            } ,
            {
            headers:{
                "Content-Type": "application/json",
                "Accept":"application/json"
            }}).then(response=>{
                let temp_menus = response.data.data;
                console.log(response);
                setClientuserlists(temp_menus);
                
            }).catch(error=>{
                console.log(error);
            });
        }
        
    }

    const handleChangeClientuser = (event)=>{
        setClientuser(event.target.value);
        setClientusername(event.target.options[event.target.selectedIndex].text)
        console.log(localStorage.getItem("orderover"));
        if(localStorage.getItem("orderover") == "false"){
            setOrderdisable(false);
        }

    }

    const lunchConfirm = () =>{
        const japanTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Tokyo',hour12: false });
        const [japanMonth, japanDay, japanYear, japanHour, japanMinute] = japanTime.split(/\/|, |:|\s/);
        
        const month = japanMonth;
        const day = japanDay;
        const subcompanyinfo = { "id" : subcompany, "name" : subcompanyname};
        const clientuserinfo = { "id" : clientuser, "name" : clientusername};
        const data = {
            "today" : month + "月" + day + "日",
            "subcompany": subcompanyinfo,
            "name" : clientuserinfo,
            "lunchmenu" : menu,
            
        };
        navigate("confirm", {state: data} );
    }

    return (
        menu && 
        <div>
            <div className="lunch-detail ">
                <div className="row m-0">
                    <div className="col-md-6">
                        <div className="lunch-detail-title">
                            {menu.title}
                        </div>
                        <div className="">
                            <img className="w-100" src={BASE_BACKEND + 'image/' + menu.image_url} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className=" d-flex is-reverse">
                            <div className="lunch-price-title d-flex justify-content-end align-items-end">
                                ¥{menu.price}
                            </div>
                            <div className="lunch-detail-info">
                                {menu.detail}
                            </div>
                        </div>
                            <hr/>
                        <div>
                            <div className="row my-4">
                                <div className="col">
                                    <select
                                        
                                        id="subfactory-select"
                                        className="form-select form-select-lg mb-3"
                                        // open={open}
                                        // onClose={handleClose}
                                        // onOpen={handleOpen}
                                        value={subcompany}
                                        onChange={handleChangeSubcompany}
                                        >
                                        <option value="">
                                            現場名 選択
                                        </option>
                                        {subcompanylists && subcompanylists.map((subcompanylist)=>(
                                            <option value={subcompanylist.id} key={subcompanylist.id}>{subcompanylist.subcompany_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col">
                                    <select
                                        
                                        id="name-select"
                                        className="form-select form-select-lg mb-3"
                                        // open={open}
                                        // onClose={handleClose}
                                        // onOpen={handleOpen}
                                        value={clientuser}
                                        onChange={handleChangeClientuser}
                                        >
                                        <option value="">
                                            氏名 選択
                                        </option>
                                        {clientuserlists && clientuserlists.map((clientuserlist)=>(
                                            <option value={clientuserlist.id} key={clientuserlist.id}>{clientuserlist.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className={ orderdisable ? "text-center my-4 disable":"text-center my-4 enable"}>
                                <button disabled={orderdisable} className="w-50 lunch-detail-order-button" onClick={lunchConfirm} >注文する</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="my-4 text-center">
            <Link className="back-button" to={`/order/${company}/`} >一覧へ戻る</Link>
        </div>
    </div>
    // <ClientUnderLunchOrderConfirm />
        
        
    );
}

export default ClientUnderLunchDetail;