
import ClientUnderFooter from "./ClientUnder/ClientUnderFooter";
import ClientUnderHeader from "./ClientUnder/ClientUnderHeader";
import { Outlet } from "react-router-dom";

function ClientUnder(){
    
    return (
        <div>
            <ClientUnderHeader />
            <Outlet />
            <ClientUnderFooter />
        </div>
            
    );
}
 export default ClientUnder;