import { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import { Button } from 'primereact/button';
import { Toast } from "primereact/toast";
import { BASE_BACKEND } from "../../App";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";


function Edituser(){
    const { user_id } = useParams();
    const [ btnloading, setBtnLoading ] = useState(false);
    const [ isloading, setIsloading ] = useState(true);
    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ c_password, setC_password ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ phonenumber, setPhonenumber ] = useState("");
    const [ address, setAddress ] = useState("");
    const [ order_pass, setOrder_pass ] = useState("");
    const [ order_pass_switch, setOrder_pass_switch ] = useState(false);
    const navigate = useNavigate();
    const toast = useRef(null);
    var message = {};
    const show = (message) => {
        toast.current.show({ severity: message.severity, summary: message.summary, detail: message.message});
    };
    const regex_mail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const saveUser =()=>{
        
        const supertoken = localStorage.getItem("supertoken");
        const header = {
            headers:{
                "Authorization": "Bearer " + supertoken,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        let reqdata = {
            "password": password,
            "c_password": c_password,
            "name": username,
            "email": email,
            "phonenumber": phonenumber,
            "address": address,
            "order_pass":  order_pass

        }
        if(order_pass_switch == true){
            reqdata.order_pass_switch = "On";
        }else{
            reqdata.order_pass_switch = "Off";
        }
        
        if(reqdata.name === ""){
            message = {
                severity: 'error', 
                summary: '現場名を入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(!reqdata.email || regex_mail.test(reqdata.email) === false){
            message = {
                severity: 'error', 
                summary: 'メールアドレスを入力してください。',
                message:"注文したお弁当名と数は自動で生成します。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata.password === ""){
            message = {
                severity: 'error', 
                summary: 'パスワードを入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata.c_password !== reqdata.password){
            message = {
                severity: 'error', 
                summary: 'パスワードが同じではありません。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata.phonenumber === ""){
            message = {
                severity: 'error', 
                summary: '電話番号してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata.address === ""){
            message = {
                severity: 'error', 
                summary: 'アドレスしてください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata.order_pass === ""){
            message = {
                severity: 'error', 
                summary: '注文暗証番号してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else{
            let url = BASE_BACKEND + "api/"
            if(user_id === "new"){
                url += "register";
            }else{
                url += "edituser/" + user_id;
            }
            console.log(reqdata);
            setBtnLoading(true);
            axios.post(url,reqdata,header)
            .then(res=>{
                console.log(res.data);
                if(res.data){
                    setBtnLoading(false);
                    message = {
                        severity: 'success', 
                        summary: '正確に保存しました',
                        message:"ありがとうございます。"};
                    show(message);
                    // navigate("/super");
                }
                
            }).catch(err=>{
                console.log(err.response);
                        if(err.response.data.data.userid == "validation.unique"){
                            message = {
                                severity: 'error', 
                                summary: '同じユーザーIDが既に存在しています。',
                                message:"もう一度入力してください。"};
                            show(message);
                        }
                        if(err.response.data.data.email == "validation.unique"){
                            message = {
                                severity: 'error', 
                                summary: '同じメールアドレスが既に存在しています。',
                                message:"もう一度入力してください。"};
                            show(message);
                        }
                        if(err.response.data.data.phonenumber == "validation.unique"){
                            message = {
                                severity: 'error', 
                                summary: '同じ電話番号が既に存在しています。',
                                message:"もう一度入力してください。"};
                            show(message);
                        }
                        if(err.response.data.data.password == "validation.min.string"){
                            message = {
                                severity: 'error', 
                                summary: 'パスワードは8字以上入力してください。',
                                message:"もう一度入力してください。"};
                            show(message);
                        }
                        setBtnLoading(false);
                   
            });
        }
    }
    useEffect(()=>{
        if(user_id !== "new" ){
            const supertoken = localStorage.getItem("supertoken");
            const header = {
                headers:{
                    "Authorization": "Bearer " + supertoken,
                    "Content-Type": "application/json",
                    "Accept":"application/json"
                }
            }
            const url = BASE_BACKEND + "api/user/" + user_id;
                axios.get(url,header)
                .then(res=>{
                    console.log(res);
                    if(res.data){
                        setUsername(res.data.name);
                        setEmail(res.data.email);
                        setPassword(res.data.passwordshow);
                        setC_password(res.data.passwordshow);
                        setPhonenumber(res.data.phonenumber);
                        setAddress(res.data.address);
                        setOrder_pass(res.data.order_pass);
                        if(res.data.order_pass_switch == "On"){
                            setOrder_pass_switch(true);
                        }else{
                            setOrder_pass_switch(false);
                        }
                        setIsloading(false);
                    }
                    
                    
                }).catch(err=>
                    {
                        console.log(err);
                        if(err.response.data.data.email == "validation.unique"){
                            message = {
                                severity: 'error', 
                                summary: '同じメールアドレスが既に存在しています。',
                                message:"もう一度入力してください。"};
                            show(message);
                        }
                        if(err.response.data.data.phonenumber == "validation.unique"){
                            message = {
                                severity: 'error', 
                                summary: '同じ電話番号が既に存在しています。',
                                message:"もう一度入力してください。"};
                            show(message);
                        }
                        setBtnLoading(false);
                    });
        }else{

            setIsloading(false);
        }

            
    },[]);
    return(
        <div>
            
            <div>
                <div>
                    <div className="d-flex justify-content-start">
                        <div className="switch-title switch-border">ユーザー登録</div>
                        
                    </div>
                </div>
            </div>
            <div className="white-box p-4 position-relative">
            <Toast ref={toast} />
                {isloading && <Loading />}
                <div className="d-flex flex-row justify-content-center">
                            <div className="w-75 p-4">
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="username" className="col-sm-4 col-form-label text-end">現場名</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control"  value={username} onChange={(e)=>setUsername(e.target.value)}  />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-end">メール</label>
                                    <div className="col-sm-8">
                                        <input type="email"  className="form-control" autoComplete="off" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-end">パスワード</label>
                                    <div className="col-sm-8">
                                        <input type="password" className="form-control" autoComplete="new-password" value={password} onChange={(e)=>setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-end">再パスワード</label>
                                    <div className="col-sm-8">
                                        <input type="password" className="form-control"  step=""  value={c_password} onChange={(e)=>setC_password(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="phonenumber" className="col-sm-4 col-form-label text-end">電話番号</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control"  value={phonenumber} onChange={(e)=>setPhonenumber(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="companyname" className="col-sm-4 col-form-label text-end">住所</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control"  value={address} onChange={(e)=>setAddress(e.target.value)} />
                                    </div>
                                </div>
                                
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="faxcontent" className="col-sm-4 col-form-label text-end">注文暗唱</label>
                                    <div className="col-sm-8">
                                        <input  className="form-control" value={order_pass} onChange={(e)=>setOrder_pass(e.target.value)} />
                                        <div className="form-check form-switch mt-4">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={order_pass_switch}  onChange={(e)=>setOrder_pass_switch(e.target.checked)} />
                                            <label className="form-check-label" for="flexSwitchCheckChecked">暗唱入力許可設定</label>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            
                            </div>
                            
                            
                </div>
                <div className="d-flex flex-column justify-content-center mt-4 pt-4">
                    <div className="text-center add">
                        <Button label="設定内容を確認" className="lunch-edit-btn py-2 w-25" loading={btnloading} onClick={saveUser} />
                        
                    </div>
                    <div className="text-center mt-4 add">
                        <Button label="管理一覧に戻る" className="lunch-edit-btn py-2 w-25 bg-secondary text-dark" onClick={()=>navigate("/super")} />
                        
                    </div>
                </div>
                        
                    
            </div>
        </div>
    );
}

export default Edituser;