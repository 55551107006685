import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Toast } from "primereact/toast";
import { InputText } from 'primereact/inputtext';
import { BASE_BACKEND } from '../../App';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Superadmin({setUser_idhandle}){
    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const toast = useRef(null);
    var message = {};
    const show = (message) => {
        toast.current.show({ severity: message.severity, summary: message.summary, detail: message.message});
    };
    const editProduct = (user) => {
        let id;
        if(user === "new"){
            id = "new";
        }else{
            id = user.id
        }
        setUser_idhandle(id);
        navigate("" + id);
    };

    const confirmDeleteProduct = (user) => {
        console.log(user);
        
        const url = BASE_BACKEND + "api/user/" + user.id;
        axios.delete(url,header)
        .then(res=>{
            console.log(res);
            setRefresh(!refresh);
        })
        .catch(error=>console.log(error));
    };
    const header = (
        <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between">
            <div>
                <h4 className="m-0">一般ユーザーリスト</h4>
            </div>
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="検索キーワード" />
                </span>
            </div>
        </div>
    );
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="me-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    };
    const adminButton = (rowData) => {
        return <Tag value="アドミンページー"  className='pointer' severity="primary" onClick={() => goAdmin(rowData)} />;
    };
    const orderButton = (rowData) => {
        return <Tag value="注文ページー" className='p-1 pointer' severity="success"  onClick={() => goOrder(rowData)} ></Tag>;
    };
    const goAdmin = (user) => {
        const url = BASE_BACKEND + "api/login";

        const data = {
            "email": user.email,
            "password": user.passwordshow
        };
        console.log(data)
        axios.post(url,data, header)
        .then(res => {
            console.log(res.data);
            const userinfo = res.data.data;
            if(userinfo.token){
                // localStorage.clear();
                localStorage.setItem('token', userinfo.token);
                localStorage.setItem('usertype', userinfo.user);
                navigate("/admin/restaurant");
            }
            
        }).catch((err)=>{
            console.log(err.response.data);
            if(err.response.data.message === "invalid login credentials"){
                message = {
                    severity: 'error', 
                    summary: '入力した情報が正確ではありません。',
                    message:"もう一度入力してください。"};
                show(message);
            }else if(err.response.data.message === "Validation Error"){
                message = {
                    severity: 'error', 
                    summary: '入力欄に正確に入力してください。',
                    message:"もう一度入力してください。"};
                show(message);
            }
            
        });
        
    }
    const goOrder = (user) => {
        navigate("/order/" + user.id);
    }
    useEffect(() => {
        const supertoken = localStorage.getItem("supertoken");
        const header = {
            headers:{
                "Authorization": "Bearer " + supertoken,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const url = BASE_BACKEND + "api/getuserlists";
            axios.get(url,header)
            .then(res=>{
                console.log(res.data);
                if(res.data){
                    setCustomers(res.data);
                }
            }).catch(err=>console.log(err));
    }, [refresh]);
    return(
        <div>
            <Toast ref={toast} />
                    <div>
                        <div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="switch-title switch-border">スーバーアドミンページー</div>
                                <div>
                                    <button onClick={()=>editProduct("new")} className="add-sth-btn">新規ユーザー追加 <i className="bi bi-plus-circle-fill"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white-box p-4 position-relative">
                        <div className="card">
                            <DataTable value={customers} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                    currentPageReportTemplate="{totalRecords} の {first} から {last} まで "  globalFilter={globalFilter} header={header}>
                                <Column header="No" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
                                <Column field="name" header="現場名" sortable style={{  minWidth: '10rem' }}></Column>
                                <Column field="email" header="メール" sortable style={{ minWidth: '10rem' }}></Column>
                                <Column field="passwordshow" header="パスワード" sortable style={{  minWidth: '10rem' }}></Column>
                                <Column field="phonenumber" header="電話番号" sortable style={{ minWidth: '10rem' }}></Column>
                                <Column field="address" header="住所" sortable style={{  minWidth: '10rem' }}></Column>
                                <Column field="order_pass" header="注文暗唱" style={{  minWidth: '8rem' }}></Column>
                                <Column field="order_pass_switch" header="暗唱入力許可" style={{ minWidth: '8rem' }}></Column>
                                <Column body={actionBodyTemplate}  header="編集/削除" style={{  minWidth: '10rem' }}></Column>
                                <Column body={adminButton}  header="管理画面" style={{  minWidth: '10rem' }}></Column>
                                <Column body={orderButton}  header="注文フロント" style={{  minWidth: '8rem' }}></Column>
                            </DataTable>
                        </div>
                    </div>
                
        </div>
    );
}

export default Superadmin;