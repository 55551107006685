import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AlertPass from "../AlertPass";
import axios from "axios";
import { Button } from 'primereact/button';
import { BASE_BACKEND } from "../../../App";
import AlertSuccess from "../AlertSuccess";
// import './Button.css';


function ClientUnderLunchOrderConfirm(){
    const [btnloading, setBtnLoading] = useState(false);
    const navigate = useNavigate();
    const { company } = useParams();
    const [isconfirm, setIsconfirm] = useState(false);
    const [issuccess, setIssuccess] = useState(false);
    const [orderpass, setOrderpass] = useState();
    
    const location = useLocation();
    const data  = location.state;
    console.log(data);
    const goBack = () => {
        navigate(-1);
    }
    const sendOrder = () =>{
        setBtnLoading(true);
        if(orderpass.switch === "On"){
            setIsconfirm(true);
            console.log(orderpass.switch);
        }else{
            console.log("no");
            const japanTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Tokyo',hour12: false });
            const [japanMonth, japanDay, japanYear, japanHour, japanMinute] = japanTime.split(/\/|, |:|\s/);
            
            var year = japanYear;
            var months = ("0" + japanMonth).slice(-2);
            var day = String(japanDay).padStart(2, '0');
            const url = BASE_BACKEND + "api/" + company + "/lunchorderlists";
            const header = {
                headers:{
                    "Content-Type": "application/json",
                    "Accept":"application/json"
                }
            }
            const reqdata = {
                "subcompany_id": data.subcompany.id,
                "orderer_id":data.name.id,
                "orderedlunch_id": data.lunchmenu.id,
                "company_identify":company,
                "day": day,
                "yearmonth": year + "-" + months
            }
            console.log(data);
                axios.post(url,reqdata,header)
                    .then((res)=>{
                        console.log(res.data);
                        setIssuccess(true);
                        setBtnLoading(false);
                    }).catch(err=>console.log(err.response.data));
        }
        

                
        
    }
    useEffect(()=>{
        const url = BASE_BACKEND+ "api/" + company + "/password";
        const header = {
            headers:{
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
            axios.get(url,header)
                .then((res)=>{
                    console.log(res.data);
                    setOrderpass(res.data);
                        
                }).catch(err=>console.log(err));
    },[])
    return (
        data &&
        <div className="confirm">
            <div className="lunch-detail">
                <div className="title">注文内容の確認</div>
                <div>
                    <div className="detail">
                    <div className="d-flex column-rows-2">
                        <div className="col-sm-3 col-6 detail-label">注文日</div>
                        <div className="col-sm-9 col-6 detail-info">{data.today}</div>
                    </div>
                    <div className="d-flex">
                        <div className="col-sm-3 col-6 detail-label">会社名（部門名）</div>
                        <div className="col-sm-9 col-6 detail-info">{data.subcompany.id ? data.subcompany.name : data.subfactory.name}</div>
                    </div>
                    <div className="d-flex">
                        <div className="col-sm-3 col-6 detail-label">氏名</div>
                        <div className="col-sm-9 col-6 detail-info">{data.name.name}</div>
                    </div>
                    <div className="d-flex">
                        <div className="col-sm-3 col-6 detail-label">商品名</div>
                        <div className="col-sm-9 col-6 detail-info">{data.lunchmenu.title}</div>
                    </div>
                    <div className="d-flex">
                        <div className="col-sm-3 col-6 detail-label">金額</div>
                        <div className="col-sm-9 col-6 detail-info">{data.lunchmenu.price}</div>
                    </div>    
                    </div>
                </div>
                <div className="remind">
                    <div>上記の内容で注文を確定しますか</div>
                    <div>※「注文する」を押すと注文が確定されます</div>
                </div>
                <div className="d-flex is-reverse-row">
                    <div className="col text-center my-4 disable">
                        <button className="w-50 lunch-detail-order-button" onClick={goBack}>キャンセルして戻る</button>
                    </div>
                    <div className="col text-center my-4 enable">
                        <button className="" onClick={sendOrder}></button>
                        <Button label="注文する" className="w-50 lunch-detail-order-button" loading={btnloading} onClick={sendOrder} />
                        

                    </div>
                </div>
            </div>

            {isconfirm && <AlertPass orderdata={data} orderpass={orderpass} /> }
            { issuccess && <AlertSuccess />}
        </div>
    );
}
export default ClientUnderLunchOrderConfirm;