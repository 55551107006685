import { useState, useEffect, useRef } from "react";
import ImageUpload from "../../ImageUpload";

import { useNavigate } from "react-router";
import axios from "axios";
import { BASE_BACKEND } from "../../../../App";
import { Button } from 'primereact/button';
import { Toast } from "primereact/toast";

function MenuInput({onCloseModal, id, restaurantid}){
    const [ btnloading, setBtnLoading ] = useState(false);
    const navigate = useNavigate();
    const [lunchname, setLunchname] = useState("");
    const [lunchprice, setLunchprice] = useState("");
    const [lunchdetail, setLunchdetail] = useState("");
    const [lunchimgurl, setLunchimgurl] = useState("");
    const [forupimage, setForupImage] = useState(null);
    const toast = useRef(null);
    var message = {};
    const show = (message) => {
        toast.current.show({ severity: message.severity, summary: message.summary, detail: message.message});
    };
    

    useEffect(()=>{
        console.log(restaurantid);
        if(id !== "new"){
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const url = BASE_BACKEND + "api/lunchmenulists/" + id
        axios.get(url,header)
        .then(res=>{
            console.log(res.data.data.image_url);
            setLunchname(res.data.data.title);
            setLunchprice(res.data.data.price);
            setLunchdetail(res.data.data.detail);
            setLunchimgurl(res.data.data.image_url);
        }).catch(err=>console.log(err.response.data));
        
    }
    },[])
    
    const   handleImageChange=(upImage)=>{
        setForupImage(upImage);
    }
    const saveLunch = () =>{
        setBtnLoading(true);
        if(restaurantid === "new"){
            message = {
                severity: 'error', 
                summary: '先に注文先より入力してください。',
                message:"もう一してみてください。"};
            show(message);
            setBtnLoading(false);
        }else{
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const data = {
            "title":lunchname,
            "price":lunchprice,
            "detail":lunchdetail,
            "image":forupimage,
            "restaurant_id":restaurantid,
            "image_url":lunchimgurl
        }
        console.log(data);
        if(id === "new"){
            const url = BASE_BACKEND + "api/lunchmenulists";
            console.log(url);
            if(data.title === ""){
                message = {
                    severity: 'error', 
                    summary: 'メニュー名を入力してください。',
                    message:"もう一度入力してください。"};
                show(message);
                setBtnLoading(false);
            }else if(data.price === "" || !data.price.match(/^[a-zA-Z0-9]+$/)){
                message = {
                    severity: 'error', 
                    summary: '金額正確に入力してください。',
                    message:"もう一度入力してください。"};
                show(message);
                setBtnLoading(false);
            }else if(data.detail === ""){
                message = {
                    severity: 'error', 
                    summary: '説明を入力してください。',
                    message:"もう一度入力してください。"};
                show(message);
                setBtnLoading(false);
            }else if(data.image === null){
                message = {
                    severity: 'error', 
                    summary: '画像ファイルを選択して、切り取ってください。',
                    message:"もう一度選択してください。"};
                show(message);
                setBtnLoading(false);
            }else{
                axios.post(url,data,header)
                .then((res)=>{
                        console.log(res);
                        onCloseModal(false);
                        setBtnLoading(false);
                }).catch(err=>console.log(err));
            }
            
        }else{
            
            console.log(id);
            const url1 = BASE_BACKEND + "api/lunchmenulists/" + id;
                axios.put(url1,data,header)
                    .then((res)=>{
                        console.log(res.data);
                        onCloseModal(false);
                        setBtnLoading(false);
                    }).catch(err=>console.log(err.response.data));
        }
        }
        
        
        
        
    }
    const closeModal = () =>{
        onCloseModal(false);
    }
    return (
        <div className="admin-modal-back">
            <Toast ref={toast} />
            <div className="admin-modal">
                    <div className="d-flex justify-content-center">
                        <div className="switch-title switch-border m-4">メニューの追加</div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-8">
                            <div className="mb-3 w-100 row">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-end">メニュー名</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" placeholder="メニューの名前を入力"  value={lunchname} onChange={(e)=>setLunchname(e.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 w-100 row">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-end">金額</label>
                                <div className="col-sm-8">
                                    <input type="number" className="form-control" placeholder="金額を入力"  value={lunchprice} onChange={(e)=>setLunchprice(e.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 w-100 row">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-end">説明</label>
                                <div className="col-sm-8">
                                    <textarea rows="6" type="text" className="form-control" placeholder="商品の説明を入力"  value={lunchdetail} onChange={(e)=>setLunchdetail(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <ImageUpload onDataChange={handleImageChange} currentimg={lunchimgurl} />
                            
                        </div>
                    </div>
                    <div className=" add">
                        <Button label="登録" className="lunch-edit-btn py-2 w-25" loading={btnloading} onClick={saveLunch} />
                    </div>
                    <div className="mt-3">
                        <button onClick={closeModal} className="cancel-btn"><i className="bi bi-x-lg"></i> キャンセル</button>
                    </div>
            </div>
        </div>
    );
}
export default MenuInput;