import { useState, useRef } from "react";
import axios from "axios";
import {useNavigate } from "react-router-dom";
import loginlogo from "../../../assets/image/admin/login-logo.svg"
import { Toast } from "primereact/toast";
import { BASE_BACKEND } from "../../../App";
import { Button } from 'primereact/button';


function Login(){
    const [ btnloading, setBtnLoading ] = useState(false);
    const [ email, setEmail ] = useState();
    const [ password, setPassword ] = useState();
    const navigate = useNavigate();
    const toast = useRef(null);
    var message = {};
    const show = (message) => {
        toast.current.show({ severity: message.severity, summary: message.summary, detail: message.message});
    };

    const header = {
        headers : {
            "Content-Type" : "application/json",

        }

    }
    const submitForm = (event)=>{
        setBtnLoading(true);
        event.preventDefault();
        const url = BASE_BACKEND + "api/login";

        const data = {
            "email": email,
            "password": password
        };
        
        axios.post(url,data, header)
        .then(res => {
            console.log(res.data);
            const userinfo = res.data.data;
            if(userinfo.token){
                localStorage.removeItem("usertype");
                localStorage.removeItem("supertoken");
                localStorage.removeItem("token");
                localStorage.setItem('usertype', userinfo.user);
                if(userinfo.user == "superadmin"){
                    localStorage.setItem('supertoken', userinfo.token);
                    navigate("/super");
                }else{
                    localStorage.setItem('token', userinfo.token);
                    navigate("/admin/restaurant");
                }
                setBtnLoading(false);
            }
            
        }).catch((err)=>{
            console.log(err.response.data);
            if(err.response.data.message === "invalid login credentials"){
                message = {
                    severity: 'error', 
                    summary: '入力した情報が正確ではありません。',
                    message:"もう一度入力してください。"};
                show(message);
            }else if(err.response.data.message === "Validation Error"){
                message = {
                    severity: 'error', 
                    summary: '入力欄に正確に入力してください。',
                    message:"もう一度入力してください。"};
                show(message);
            }
            
            setBtnLoading(false);
        });

        

    }

    
    


    return (
        <div>
            <Toast ref={toast} />
            <div className="login-page d-flex">
                <div className="login-right col-sm-6 d-flex align-items-center">
                    <div className="text-center w-100"><img src={loginlogo} alt="" /></div>
                                       
                </div>
                <div className="col-sm-6 d-flex align-items-center justify-content-center">
                    <div className="col-sm-6">
                            <form onSubmit={submitForm} className="login" >
                                <label>メールアドレス</label>
                                <input type="email" placeholder="メールアドレスを入力してください"  className="form-control my-3" onChange={(e)=>setEmail(e.target.value)} />
                                <label>パスワード</label>
                                <input type="password" placeholder="パスワードを入力してください"  className="form-control my-3" onChange={(e)=>setPassword(e.target.value)} />
                                
                                <Button type="submit" label="ログイン" className="mt-3 w-100 login-btn" loading={btnloading} />
                            </form>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Login;