import axios from "axios";
import { BASE_BACKEND } from "../../../App";
import AdminFooter from "../HeaderFooter/AdminFooter";
import AdminHeader from "../HeaderFooter/AdminHeader";
import DashboardMain from "./DashboardMain";
import { useEffect, useState } from "react";
import Superwrap from "../../superadmin/Superwrap";


function AdminDashboard(){
    const [ username, setUsername] = useState('');
    const token = localStorage.getItem("token");
    const supertoken = localStorage.getItem("supertoken");
    const usertype = localStorage.getItem("usertype");
    useEffect(()=>{
        let header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        if(usertype == "superadmin"){
            header = {
                headers:{
                    "Authorization": "Bearer " + supertoken,
                    "Content-Type": "application/json",
                    "Accept":"application/json"
                }
            }
        }
        const url = BASE_BACKEND + "api/user";
            axios.get(url,header)
            .then(res=>{
                console.log(res.data);
                if(res.data){
                    setUsername(res.data.name);
                }
                
            }).catch(err=>console.log(err.response.data));
    },[usertype]);
    return(
        <div>
            <AdminHeader username={username} supertoken={supertoken} />
            { usertype === "superadmin" ?  <Superwrap /> : <DashboardMain />}
            
            <AdminFooter />
        </div>
    );
}

export default AdminDashboard;