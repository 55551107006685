import { useState, useEffect,useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertSuccess from "./AlertSuccess";
import axios from "axios";
import { BASE_BACKEND } from "../../App";
import { Toast } from "primereact/toast";
import { Button } from 'primereact/button';

function AlertPass({orderdata, orderpass}){
    const [ btnloading, setBtnLoading ] = useState(false);
    const [ ispassed , setIspassed] = useState(false);
    const [ password, setPassword] = useState("");
    const { company } = useParams();
    const toast = useRef(null);
    var message = {};
    const show = (message) => {
        toast.current.show({ severity: message.severity, summary: message.summary, detail: message.message});
    };
    // alert(company)
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const goSuccess = () => {
        setBtnLoading(true);
        const japanTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Tokyo',hour12: false });
        const [japanMonth, japanDay, japanYear, japanHour, japanMinute] = japanTime.split(/\/|, |:|\s/);
        
        var year = japanYear;
        var months = ("0" + japanMonth).slice(-2);
        var day = String(japanDay).padStart(2, '0');
        if(orderpass.pass == password){
            const url = BASE_BACKEND + "api/" + company + "/lunchorderlists";
            const header = {
                headers:{
                    "Content-Type": "application/json",
                    "Accept":"application/json"
                }
            }
            const data = {
                "subcompany_id":orderdata.subcompany.id,
                "orderer_id":orderdata.name.id,
                "orderedlunch_id": orderdata.lunchmenu.id,
                "company_identify":company,
                "day": day,
                "yearmonth": year + "-" + months
            }
            console.log(data);
                axios.post(url,data,header)
                    .then((res)=>{
                        console.log(res.data);
                        setBtnLoading(false);
                    }).catch(err=>{
                        console.log(err.response.data);
                        setBtnLoading(false);
                    });
            
            setIspassed(false);
        }else{
            message = {
                severity: 'error', 
                summary: '注文暗証番号が違います。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }
    }
    useEffect(()=>{
        
        
        if(orderpass.switch === 0){
            setIspassed(false);
            
        }else{
            setIspassed(true);
        }
        
            
    },[]);
    return(
        ispassed == true ? 
        <div className="client-alert-back">
            <Toast ref={toast} />
            <div className="client-alert">
                <div className="alert-title">暗証番号を入力</div>
                <div className="alert-body d-flex flex-column justify-content-around">
                    <input type="password" className="form-control w-75 m-auto" value={password} onChange={(e)=>setPassword(e.target.value)} />
                    <div className="alert-detail">暗証番号がわからない場合は、管理者へご確認ください。</div>
                </div>
                <div className="d-flex justify-content-around">
                    <div className="mx-4 w-50">
                        <button className="back-button w-100" onClick={goBack}>キャンセル</button>
                    </div>
                    <div className="mx-4 w-50 pass">
                        <Button label="確認"  className="confirm-button w-100" loading={btnloading} onClick={goSuccess} />
                        
                    </div>
                </div>
            </div>
        </div> :
        <div>
            <AlertSuccess />
        </div>
        
    )
}

export default AlertPass;