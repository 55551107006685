import {  useParams } from "react-router-dom";
import { BASE_BACKEND } from "../../App";
import axios from "axios";
import { useEffect, useState } from "react";

function AlertWarningEnd({close}){
    
    const { company } = useParams("");
    const [ alertimage, setAlertimage ] = useState(null);
    const closeModal = () =>{
        close(false);
    }

    useEffect(()=>{
        const url = BASE_BACKEND + "api/" + company + "/getalertimage";
        const header = {
            headers:{
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
            axios.get(url,header)
                .then((res)=>{
                    console.log(res.data);
                    setAlertimage(res.data.alert_image);
                        
                }).catch(err=>console.log(err));
    },[])

    return(
        <div className="client-alert-back">
            <div className="client-alert">
                <div className="alert-title alert-text">締め切り時間が迫っています</div>
                <div className="alert-body">
                    {alertimage == null ?  <div className="alert-detail">
                        <span>まもなく、お弁当の注文締め切り時刻です。</span>
                        <span>注文を忘れている方は、大至急注文お願いします。</span>
                    </div> :
                    <div className=''>
                        <img className='image-full lunchmenu-image' src={BASE_BACKEND + "alertimages/" + alertimage}  alt={BASE_BACKEND + "alertimages/" + alertimage} />
                    </div>}
                </div>
                    <div className="mx-4 text-center pass">
                        <button className="confirm-button w-50" onClick={closeModal}>確認</button>
                    </div>
            </div>
        </div>
    )
}

export default AlertWarningEnd;