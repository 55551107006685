import { useState, useEffect } from "react";
import adminLunchimage from "../../../../assets/image/admin/lunch.png"
import MenuInput from "./MenuInput";
import axios from "axios";
import { BASE_BACKEND } from "../../../../App";
import Loading from "../../../Loading";

function ManageLunch({currentrestaurantid, setIsfocus}){
    const [ isloading, setIsloading ] = useState(true);
    const [showmodal, setShowmodal] = useState(false);
    const [isdel, setIsdel] = useState(false);
    const [currentlunchid, setCurrentlunchid] = useState();
    const [lunchmenulists, setLunchmenulists] = useState();
    const lunch = (id) =>{
        setShowmodal(true);
        setCurrentlunchid(id);
    }
    const deleteLunch = (id) =>{
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const url = BASE_BACKEND + "api/lunchmenulists/" + id;
                axios.delete(url,header)
                    .then((res)=>{
                        console.log(res);
                        setIsdel(!isdel);
                    }).catch(err=>console.log(err.response.data));
    }
    const modalClose = (isClose) =>{
        setShowmodal(isClose);
    }
    useEffect(()=>{
        setIsfocus("lun");
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const url = BASE_BACKEND + "api/lunchmenuadminlists/" + currentrestaurantid;
        console.log(currentrestaurantid)
        axios.get(url,header)
        .then(res=>{
            console.log(res.data);
            setLunchmenulists(res.data);
            setIsloading(false);
        }).catch(err=>console.log(err.response.data));
    },[showmodal,isdel]);
    return (
        <div>
                        <div className="d-flex row m-0">
                        {isloading && <Loading />}
                            <div className="col-sm-10 row row-cols-2 m-0">
                            {Array.isArray(lunchmenulists) && lunchmenulists.map((lunchmenu)=>
                             <div className="col-lg-3 col-md-4" key={lunchmenu.id}>
                                    <div className="admin-lunchbox">
                                        <div className="admin-lunchtitle">{lunchmenu.title}</div>
                                        <div className="admin-lunchprice fw-bold text-end">¥{lunchmenu.price}</div>
                                        <div>
                                            <img className="admin-lunchimage" src={BASE_BACKEND + 'image/' + lunchmenu.image_url} alt="asd" />
                                        </div>
                                        <div className="d-flex justify-content-around">
                                            <button className="lunch-del-btn" onClick={()=>deleteLunch(lunchmenu.id)}>削除</button>
                                            <button className="lunch-edit-btn" onClick={()=>lunch(lunchmenu.id)}>編集</button>
                                        </div>
                                    </div>
                                </div>
                        
                            )}
                               
                                
                            </div>
                            <div className="col-sm-2">
                                <button className=" attach-btn add-sth-btn" onClick={()=>lunch("new")}>メニューの追加 <i className="bi bi-plus-circle-fill"></i></button>
                            </div>
                        </div>
                        {showmodal && <MenuInput onCloseModal={modalClose} id={currentlunchid} restaurantid={currentrestaurantid} />}
                    </div>
    );
}
export default ManageLunch;