
import React, { useEffect, useState } from "react";
import EasyCrop from './imageCrop/EasyCrop';
import { BASE_BACKEND } from "../../App";


function ImageUpload({onDataChange, currentimg}) {
  
  return (
    <div className="">
          
      {/* {currentimg == "" ? ( image == null ? 
         <div
          onDragEnter={(e) => handleEnter(e)}
          onDragLeave={(e) => handleLeave(e)}
          onDragOver={(e) => handleOver(e)}
          onDrop={(e) => handleUpload(e)}
          // onClick={(e) => handleUpload(e)}
          className={`upload${
          highlight ? " is-highlight" : drop ? " is-drop" : ""
          }`}
      >
          <p>Drop image here</p>
          
      </div> 
      : <EasyCrop onDataChange={onDataChange} image={image}  />) 
      : <div className="cropped-image-container">
            <img className="border cropped-image" src={currentimg !== null ? BASE_BACKEND + "image/" + currentimg : defaultimage} alt={currentimg} />
            
        </div>} */}
        <EasyCrop onDataChange={onDataChange} currentimg={currentimg} />
      
        
    </div>
  );
}

export default ImageUpload;
