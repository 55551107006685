import React, { lazy, Suspense } from 'react';
import { Link, Route, Routes, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// import AllHistoryView from "./Edits/AllHistoryView";
// import DayHistoryView from "./Edits/DayHistoryView";
// import NameHistoryView from "./Edits/NameHistoryView";
// import SubFactoryHistoryView from "./Edits/SubFactoryHistoryView";
const AllHistoryView = lazy(() => import('./Edits/AllHistoryView'));
const DayHistoryView = lazy(() => import('./Edits/DayHistoryView'));
const NameHistoryView = lazy(() => import('./Edits/NameHistoryView'));
const SubFactoryHistoryView = lazy(() => import('./Edits/SubFactoryHistoryView'));


function OrderHistory({showContent}){
    const [isfocus, setIsfocus] = useState("all");
    const [month, setMonth] = useState();
    const catchHistory = (e)=>{
        setMonth(e.target.value);
        
    }
    useEffect(()=>{
        const japanTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Tokyo',hour12: false });
        const [japanMonth, japanDay, japanYear, japanHour, japanMinute] = japanTime.split(/\/|, |:|\s/);
        
        var year = japanYear;
        var thismonth = ("0" + japanMonth).slice(-2);
        var day = String(japanDay);
        setMonth(year + "-" + thismonth);
        showContent("orderhistory");

        
    },[]);
    return (
        <div className="position-relative">
            <div>
                <div className="d-flex justify-content-center">
                    <Link to="allhistory" onClick={()=>setIsfocus("all")} className={isfocus === "all" ? "switch-title switch-border" : "switch-title"}>集計一覧</Link>
                    <Link to="dayhistory" onClick={()=>setIsfocus("day")} className={isfocus === "day" ? "switch-title switch-border" : "switch-title"}>日付別一覧</Link>
                    <Link to="namhistory" onClick={()=>setIsfocus("nam")} className={isfocus === "nam" ? "switch-title switch-border" : "switch-title"}>氏名別一覧</Link>
                    <Link to="subhistory" onClick={()=>setIsfocus("sub")} className={isfocus === "sub" ? "switch-title switch-border" : "switch-title"}>現場別一覧</Link>
                </div>
                <div>
                <input type="month" className="month-picker" id="bdaymonth" name="bdaymonth" lang="ja" value={month} onChange={catchHistory} />
                </div>
            </div>
            
            <div className="">
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<AllHistoryView yearMonth={month} />} />
                    <Route path="allhistory" element={<AllHistoryView yearMonth={month} />} />
                    <Route path="dayhistory" element={<DayHistoryView yearMonth={month} />} />
                    <Route path="namhistory" element={<NameHistoryView yearMonth={month}  />} />
                    <Route path="subhistory" element={<SubFactoryHistoryView yearMonth={month} />} />
                </Routes>
                </Suspense>
            </div>
        </div>
    );
}

export default OrderHistory;