import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RestaurantEdit from "./Edits/RestaurantEdit";
import axios from "axios";
import { BASE_BACKEND } from "../../../App";
import Loading from "../../Loading";

// import { header } from "../AdminDashboard/AdminDashboard";

function Restaurant({ showContent }){
    const [ restaurants, setRestaurants ] = useState();
    const [ del, setDel ] = useState(false);
    const [isloading, setIsloading] = useState(true);
    const navigate = useNavigate();
    const editRestaurant = (lunch_id = "new") =>{ 
        navigate(lunch_id + "/managerestaurant");
    };
    const delRestaurant = (lunch_id) =>{ 
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        };
        const url = BASE_BACKEND + "api/bentos/" + lunch_id
        axios.delete(url,header)
        .then(res=>{
            console.log(res);
            if(res.data === "Del"){
                setDel(!del);
            }
            
            
        }).catch(err=>console.log(err.response.data));
    }
    useEffect(()=>{
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        showContent("restaurant");
        const url = BASE_BACKEND + "api/bentos"
        axios.get(url,header)
        .then(res=>{
            console.log(Object.keys(res.data.data).length);
                console.log(res.data.data);
                setRestaurants(res.data.data);
            setDel(false);
            setIsloading(false);
        }).catch(err=>console.log(err.response.data));
    },[del]);
    
    return (
        <div>
            
            <div className="d-flex justify-content-between">
                <div className="left-border-title">注文先店舗一覧</div>
                 { restaurants && (Object.keys(restaurants).length == 0  &&<div>
                    <button onClick={()=>editRestaurant("new")} className="add-sth-btn">注文先を追加 <i className="bi bi-plus-circle-fill"></i></button>
                </div>)}
            </div>
            <div className="white-box set-height overflow-auto table-responsive-xl position-relative">
            {isloading && <Loading />}
                <table className="table table-striped table-bordered table-hover align-middle">
                    <thead>
                        <tr>
                            <th className="fw-bolder">店舗名</th>
                            <th className="fw-bolder">FAX番号</th>
                            <th className="fw-bolder">メールアドレス</th>
                            <th className="fw-bolder">管理</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(restaurants) && restaurants.map((restaurant)=>
                            <tr key={restaurant.id}>
                                <td>{restaurant.name}</td>
                                <td>{restaurant.fax_number}</td>
                                <td>{restaurant.mail_address}</td>
                                <td>
                                    <div className="d-flex justify-content-around">
                                        <button onClick={()=>delRestaurant(restaurant.id)} className="del-btn">削除</button>
                                        <button onClick={()=>editRestaurant(restaurant.id)} className="edit-btn">編集</button>
                                    </div>
                                </td>
                            </tr>
                        )}
                        
                        
                        
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Restaurant;