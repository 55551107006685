import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { header } from "../AdminDashboard/AdminDashboard";

const withAuth = (Component) => {
  const AuthenticatedComponent = (props) => {
    const navigate = useNavigate();
    const { company }  = useParams();


    useEffect(() => {
      

      const interval = setInterval(() => {
        var token = localStorage.getItem("token");
        var supertoken = localStorage.getItem("supertoken");
  
        if (token || supertoken) {
          localStorage.removeItem("usertype");
          localStorage.removeItem("token");
          localStorage.removeItem("supertoken");
          navigate("/");
        }
      }, 3600000);
        // var token = localStorage.getItem("token");
  
        var token = localStorage.getItem("token");
        var supertoken = localStorage.getItem("supertoken");
      if (!token && !supertoken) {
          navigate("/");
        }
      return () => clearInterval(interval);
      
    }, [Component]);

    return <Component {...props} />;
  };

  return AuthenticatedComponent;
};

export default withAuth;