import { useNavigate, useParams } from "react-router-dom";

function AlertSuccess(){
    const { company } = useParams();
    // alert(company)
    const navigate = useNavigate();
    const goBack = () => {
        navigate(`/order/${company}/`);
    }
    return(
        <div className="client-alert-back">
            <div className="client-alert">
                <div className="alert-title">注文を送信しました</div>
                <div className="alert-body">
                    <div className="alert-detail">続けてご注文をされる場合は、トップページへ戻りご注文を始めてください。</div>
                </div>
                <div>
                    <button className="back-button" onClick={goBack}>トップへ戻る</button>
                </div>
            </div>
        </div>
    )
}

export default AlertSuccess;