import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import axios from "axios";
import { BASE_BACKEND } from "../../../App";
import Loading from "../../Loading";

function FactoryManage({showContent}){
    const [ isloading , setIsloading ] = useState(true);
    const [ factorylists, setFactorylists]=useState();
    const [isdel, setIsdel] = useState(false);
    const navigate = useNavigate();
    const editFactory = (factory_id) =>{ 
        navigate(factory_id + "/");
    };
    const delFactory = (factory_id) =>{ 
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const url = BASE_BACKEND + "api/subcompanylists/" + factory_id;
            axios.delete(url,header)
            .then(res=>{
                console.log(res.data);
                setIsdel(!isdel);
                
            }).catch(err=>console.log(err.response.data));
    };
    useEffect(()=>{
        showContent("factorymanage");
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const url = BASE_BACKEND + "api/subcompanymanage";
            axios.get(url,header)
            .then(res=>{
                console.log(res.data);
                if(res.data){
                    setFactorylists(res.data);
                    // console.log(typeof(res.data))
                    setIsloading(false);
                }
                
            }).catch(err=>console.log(err.response.data));
    },[isdel]);
    return (
            <div>
                
            <div className="d-flex justify-content-between">
                <div className="left-border-title">登録社名一覧</div>
                <div>
                    <button onClick={()=>editFactory("new")} className="add-sth-btn">登録社名追加 <i className="bi bi-plus-circle-fill"></i></button>
                </div>
            </div>
            <div className="white-box set-height overflow-auto table-responsive-xl position-relative">
            {isloading && <Loading />}
                <table className="table table-striped table-bordered table-hover align-middle">
                    <thead>
                        <tr>
                            <th className="fw-bolder">No</th>
                            <th className="fw-bolder">登録日</th>
                            <th className="fw-bolder">会社名</th>
                            <th className="fw-bolder">氏名</th>
                            <th className="fw-bolder">管理</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(factorylists) && factorylists.map((factory)=>
                            <tr key={factory.id}>
                                <td>{factory.no}</td>
                                <td>{factory.date}</td>
                                <td>{factory.subcompany_name}</td>
                                <td>{factory.name}</td>
                                <td>
                                    <div className="d-flex justify-content-around">
                                        <button onClick={()=>delFactory(factory.id)} className="del-btn">削除</button>
                                        <button onClick={()=>editFactory(factory.id)} className="edit-btn">編集</button>
                                    </div>
                                </td>
                            </tr>
                        )}
                        
                        
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FactoryManage;