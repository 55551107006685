import { useCallback, useEffect, useState } from "react";
// import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import axios from "axios";
import { MDBRange } from "mdb-react-ui-kit";
import { BASE_BACKEND } from "../../../App";

const EasyCrop = ({onDataChange , currentimg}) => {
  const [ image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [highlight, setHighlight] = useState(false);
    const [preview, setPreview] = useState("");
    const [drop, setDrop] = useState(false);
    

  
    

    const handleImageUpload = async (e) => {
      const file = e.target.files[0] || e.dataTransfer.files[0];
      // uploadFile(file);
      setImage(URL.createObjectURL(e.target.files[0]));
      
      console.log(URL.createObjectURL(file));
    };


  const handleEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("enter!");

    preview === "" && setHighlight(true);
  };

  const handleOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("over!");

    preview === "" && setHighlight(true);
  };

  const handleLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("leave!");
    setHighlight(false);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("drop!");
    setHighlight(false);
    setDrop(true);

    const [file] = e.target.files || e.dataTransfer.files;
    uploadFile(file);
  // console.log(file);
  };

  function uploadFile(file) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      // this is the base64 data
      const fileRes = btoa(reader.result);
      console.log(`data:image/png;base64,${fileRes}`);
      setImage(`data:image/png;base64,${fileRes}`);
    };

    reader.onerror = () => {
      console.log("There is a problem while uploading...");
    };
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      
      setCroppedImage(croppedImage);
      onDataChange(croppedImage);
      
        const header = {
          headers:{
            "Content-Type": "application/upload"
          }
        };
        // send request to backend to save image
      // axios.post("http://127.0.0.1:8000/api/lunchmenulists",{image : croppedImage})
      // .then(res=>console.log(res))
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);
  useEffect(()=>{
    console.log(currentimg)
  },[image]);
  return (
    <div>
      
        <div
        onDragEnter={(e) => handleEnter(e)}
        onDragLeave={(e) => handleLeave(e)}
        onDragOver={(e) => handleOver(e)}
        onDrop={(e) => handleUpload(e)}
        className={`upload${
        highlight ? " is-highlight" : drop ? " is-drop" : ""
        }`}
          
        >
          {(currentimg !== "" && !image) &&  <div>
            <img className="border cropped-image" src={BASE_BACKEND + "image/" + currentimg } alt={currentimg} />
            
        </div>}
          { !croppedImage && <div> <div className="crop-container">
            <Cropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              zoomSpeed={4}
              maxZoom={3}
              zoomWithScroll={true}
              showGrid={true}
              aspect={16 / 9}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
            />
          </div>
          </div>}
          {(!image && !currentimg )  &&  
            <div className="p-3"><p className="">イメージファイル(jpg,jpeg,png)を選択してください。</p></div>
          }
          {(image && !croppedImage) && 
           <div className="controls">
           
           <label>
             Rotate
             <MDBRange
             value={rotation}
             min={0}
             max={360}
             step={1}
             onChange={(e) => setRotation(e.target.value)}
             className="range"
             />
           </label>
           <label>
             Zoom
             <MDBRange
               value={zoom}
               min={1}
               max={3}
               step={0.1}
               onChange={(e) => setZoom(e.target.value)}
               className="range"
             />
           </label>
         </div> }
          {croppedImage && (
            <div className=""><img className="border cropped-image" src={croppedImage} alt="cropped" /></div>
          )}
      </div>
      <button className="lunch-edit-btn py-2 w-100 crop-btn"
          style={{
            display: image === null || croppedImage !== null ? "none" : "block",
          }}
          onClick={showCroppedImage}
          >
        切り取り
      </button>
      <div className="cropped-image-container">
      </div>
      {croppedImage && <div><button className="lunch-edit-btn py-2 w-100 crop-cancel-btn" onClick={onClose}>close</button></div>}
          
      <label className="_coverImage-holder mt-2 image-upload-button">
          イメジを選択
          <input
            type="file"
            name="cover"
            onChange={handleImageUpload}
            
            style={{ display: "none" }}
          />
        </label>
    
    
    </div>
  );
};

export default EasyCrop;