import {  useNavigate, useParams } from "react-router-dom";
import { createContext, useEffect, useRef, useState } from "react";
import NameInput from "./NameInput";

import axios from "axios";
import { BASE_BACKEND } from "../../../../App";
import { Button } from 'primereact/button';
import Loading from "../../../Loading";
import { Toast } from "primereact/toast";

export const RestaurantContext = createContext();

function FactoryEdit(){
    const [ isloading, setIsloading ] = useState(true);
    const [ btnloading, setBtnLoading ] = useState(false);
    const [shownamemodal, setShownamemodal] = useState(false);
    const [clientnames, setClientnames] = useState();
    const [factoryname, setFactoryname] = useState("");
    const [currentfactoryid, setCurrentfactoryid] = useState(null);
    const [isdel, setIsdel] = useState(false);
    const { company, factoryid } = useParams();
    const navigate = useNavigate();
    const toast = useRef(null);
    var message = {};
    const show = (message) => {
        toast.current.show({ severity: message.severity, summary: message.summary, detail: message.message});
    };
    const addName=()=>{
        setShownamemodal(true);
    }
    const modalClose = (isClose) =>{
        setShownamemodal(isClose);
    }
    const savefactory = () =>{
        setBtnLoading(true);
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const data = {
            "subcompany_name":factoryname
        }
        if(data.subcompany_name === ""){
            message = {
                severity: 'error', 
                summary: '会社名を入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            
            setBtnLoading(false);
        }else{
            if(currentfactoryid === null){
                const url = BASE_BACKEND + "api/subcompanymanage"
                        axios.post(url,data,header)
                            .then((res)=>{
                                console.log(res.data.data.id);
                                setCurrentfactoryid(res.data.data.id);
                                setBtnLoading(false);
                                navigate(-1);
                                // setShownamebox(true);
                            }).catch(err=>console.log(err.response.data));
            }else{
                const url = BASE_BACKEND + "api/subcompanylists/" + currentfactoryid;
                axios.put(url,data,header)
                    .then((res)=>{
                        console.log(res.data);
                        setBtnLoading(false);
                        // setCurrentfactoryid(res.data);
                        // setShownamebox(true);
                        navigate(-1);
                    }).catch(err=>console.log(err.response.data));
            }
        }     
                

            
    }
    const delname = (name_id) => {
        const token = localStorage.getItem("token");
            const header = {
                headers:{
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json",
                    "Accept":"application/json"
                }
            }
        const url = BASE_BACKEND + "api/clientuserlists/" + name_id;
                    axios.delete(url,header)
                        .then((res)=>{
                            console.log(res);
                            setIsdel(!isdel);
                            
                        }).catch(err=>console.log(err.response.data));
    }
    useEffect(()=>{
        if(factoryid !== "new"){
            const token = localStorage.getItem("token");
            const header = {
                headers:{
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json",
                    "Accept":"application/json"
                }
            }
        const url = BASE_BACKEND + "api/subcompanylists/" + factoryid;
                    axios.get(url,header)
                        .then((res)=>{
                            console.log(res.data);
                            setFactoryname(res.data.data.subcompany_name);
                            setCurrentfactoryid(res.data.data.id);
                            setIsloading(false);
                        }).catch(err=>console.log(err.response.data));
                        
        const url1 = BASE_BACKEND + "api/clientusermanage/getcurrent/" + factoryid;
       
                    axios.get(url1,header)
                        .then((res)=>{
                            console.log(res.data);
                            setClientnames(res.data.data);
                        }).catch(err=>console.log(err.response.data));
        }else{
            // setCurrentfactoryid();
            setIsloading(false);
            if(currentfactoryid !== null){
                const token = localStorage.getItem("token");
            const header = {
                headers:{
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json",
                    "Accept":"application/json"
                }
            }
                const url1 = BASE_BACKEND + "api/clientusermanage/getcurrent/" + currentfactoryid;
                       axios.get(url1,header)
                        .then((res)=>{
                            console.log(res.data);
                            setClientnames(res.data.data);
                            
                        }).catch(err=>console.log(err.response.data));
            }
            
        }
    },[isdel,shownamemodal,currentfactoryid]);
    
    
    
    return(
        <div>
            <Toast ref={toast} />
            <div>
                <div className="d-flex justify-content-center">
                    <div className="switch-title switch-border">会社の新規追加</div>
                </div>
            </div>
            <div className="position-relative">
            {isloading && <Loading />}
                <div className="white-box set-height overflow-auto py-4">
                <div className="d-flex flex-column justify-content-center">
                        <div className="mb-3 w-75 row">
                            <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">注文先名</label>
                            <div className="col-sm-6">
                                <input type="text" placeholder="会社名を入力" className="form-control"  value={factoryname} onChange={(e)=>setFactoryname(e.target.value)} />
                            </div>
                        </div>
                        {currentfactoryid !== null &&  <div className="mb-3 w-75 row">
                                        <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">職員氏名</label>
                                            <div className="col-sm-6 position-relative">
                                            <i className="plus-name bi bi-plus-circle-fill" onClick={()=>addName()}></i>
                                                <div className="name-box col-12 p-3">
                                                    
                                                    <div className="d-flex flex-wrap">
                                                    {Array.isArray(clientnames) && clientnames.map((clientname)=>
                                                        <div className="col-md-4" key={clientname.id}>
                                                            <div className="name-tag">
                                                                <div onClick={()=>delname(clientname.id)} className="del-icon"><i className="bi bi-x"></i></div>
                                                                <div>{clientname.name}</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                        }
                        <div className="text-center add">
                                <Button label="保存する" className="lunch-edit-btn py-2 w-25" loading={btnloading} onClick={savefactory} />
                        </div>
                    </div>
                </div>
            </div>

            {shownamemodal && <NameInput onCloseModal={modalClose} factoryid={currentfactoryid} />}
        </div>
    );
}
export default FactoryEdit;