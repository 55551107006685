
import {  useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import axios from "axios";
import { BASE_BACKEND, Front_url } from "../../../App";
import Loading from "../../Loading";
import { Button } from 'primereact/button';
import { QRCodeCanvas } from "qrcode.react";
import { Toast } from "primereact/toast";
import { Calendar } from 'primereact/calendar';

function Setting({showContent}){
    const navigate = useNavigate();
    const [ btnloading, setBtnLoading ] = useState(false);
    const [ downloading, setDownloading ] = useState(false);
    const [ isloading, setIsloading ] = useState(true);
    const [ username, setUsername ] = useState("");
    const [ company_id, setCompany_id ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ companyname, setCompanyname ] = useState("");
    const [ phonenumber, setPhonenumber ] = useState("");
    const [ address, setAddress ] = useState("");
    const [ faxcontent, setFaxcontent ] = useState("");
    const [ faxsendtime, setFaxsendtime ] = useState("");

    const [image, setImage] = useState(null);
    const [alertimage, setAlertImage] = useState(null);
    const [date7, setDate7] = useState(null);



    const reqdata = {};
    const toast = useRef(null);
    var message = {};
    const show = (message) => {
        toast.current.show({ severity: message.severity, summary: message.summary, detail: message.message});
    };
    const alertImageUpload = (event) => {
        setImage(event.target.files[0]);
        setAlertImage(event.target.files[0].name);
    };
    const qrRef = useRef();
    const saveFaxinfo =()=>{
        setBtnLoading(true);
        console.log(username);
        reqdata['company_name'] =  companyname;
            reqdata['address'] = address;
            reqdata['presenter_name'] = username;
            reqdata['phonenumber']= phonenumber;
            reqdata['faxcontent']= faxcontent;
            reqdata['faxsend_time']= faxsendtime;
            reqdata['image'] = image;
        
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "multipart/form-data",
                "Accept":"application/json"
            }
        }
       
        
        const url = BASE_BACKEND + "api/faxinfolists";
        // console.log(reqdata);
        if(reqdata['presenter_name'] === "" || reqdata['presenter_name'] == null){
            message = {
                severity: 'error', 
                summary: 'お名前を入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata['company_name'] === "" || reqdata['company_name'] == null){
            message = {
                severity: 'error', 
                summary: '現場名を入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata['phonenumber'] === "" || reqdata['phonenumber'] == null){
            message = {
                severity: 'error', 
                summary: '電話番号を入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata['address'] === "" || reqdata['address'] == null){
            message = {
                severity: 'error', 
                summary: 'アドレスを入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata['faxcontent'] === "" || reqdata['faxcontent'] == null){
            message = {
                severity: 'error', 
                summary: '送りたい内容の現本を入力してください。',
                message:"注文したお弁当名と数は自動で生成します。"};
            show(message);
            setBtnLoading(false);
        }else if(reqdata['faxsend_time'] === "" || reqdata['faxsend_time'] == null){
            message = {
                severity: 'error', 
                summary: '時間を選択してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else{
            
            
            console.log(reqdata);
            axios.post(url,reqdata,header)
            .then(res=>{
                console.log(res);
                if(res.data){
                    const faxdata = res.data;
                    // setUsername(faxdata.presenter_name);
                    // setCompanyname(faxdata.company_name);
                    // setAddress(faxdata.address);
                    // setPhonenumber(faxdata.phonenumber);
                    // setFaxcontent(faxdata.faxcontent);
                    // setFaxsendtime(faxdata.faxsend_time);
                    setBtnLoading(false);
                    message = {
                        severity: 'success', 
                        summary: '正確に保存しました',
                        message:"ありがとうございます。"};
                    show(message);
                }
                
            }).catch(err=>console.log(err));
        }
    }
    useEffect(()=>{
        showContent("myaccount");
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const url = BASE_BACKEND + "api/user";
            axios.get(url,header)
            .then(res=>{
                console.log(res.data);
                if(res.data){
                    setCompany_id(res.data.id);
                    setEmail(res.data.email);
                    setPassword(res.data.passwordshow);
                }
                
            }).catch(err=>console.log(err.response.data));

            const url1 = BASE_BACKEND + "api/faxinfolists";
            axios.get(url1,header)
            .then(res=>{
                console.log(res.data[0]);
                if(res.data[0]){
                    const faxdata = res.data[0];
                    setUsername(faxdata.presenter_name);
                    setCompanyname(faxdata.company_name);
                    setAddress(faxdata.address);
                    setPhonenumber(faxdata.phonenumber);
                    setFaxcontent(faxdata.faxcontent);
                    setFaxsendtime(faxdata.faxsend_time);
                    if(faxdata.alert_image == null){
                        setAlertImage("登録した画像はありません");
                    }
                    setAlertImage(faxdata.alert_image);
                    
                }
                setIsloading(false);
            }).catch(err=>console.log(err.response.data));
    },[]);
    const qrcode = (
        <QRCodeCanvas
          id="qrCode"
          value={Front_url+ "order/" + company_id}
          size={200}
          bgColor={"#FFD658"}
          level={"H"}
        />);
    const downloadQRCode = () => {
            setDownloading(true);
            let canvas = qrRef.current.querySelector("canvas");
            let image = canvas.toDataURL("image/png");
            let anchor = document.createElement("a");
            anchor.href = image;
            anchor.download = company_id + "qr-code.png";
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            setDownloading(false);
          };
    const copyQRCodeToClipboard = async () => {
            
            try {
              const canvas = qrRef.current.querySelector('canvas');
              const blob = await new Promise(resolve => {
                canvas.toBlob(resolve, 'image/png');
              });
              await navigator.clipboard.write([
                new window.ClipboardItem({
                  [blob.type]: blob,
                }),
              ]);
              console.log('QR code copied to clipboard!');
              
              message = {
                severity: 'success', 
                summary: '正確にコーピーされました',
                message:"ありがとうございます。"};
            show(message);
            } catch (err) {
              console.error(err);
            }
        };
    const gofront = () =>{
            navigate("/order/" + company_id);
        }
    
    return(
        <div>
            
            <div>
                <div>
                    <div className="d-flex justify-content-center">
                        <div className="switch-title switch-border">マイアカウント設定</div>
                    </div>
                </div>
            </div>
            <div className="white-box p-4 position-relative">
            <Toast ref={toast} />
                {isloading && <Loading />}
                <div className="d-flex flex-row justify-content-center">
                            <div className="w-50 p-4 center-border">
                            
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-end">メールアドレス</label>
                                    <div className="col-sm-8">
                                        <input type="email" disabled className="form-control"  value={email} onChange={(e)=>setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-end">パスワード</label>
                                    <div className="col-sm-8">
                                        <input type="text"disabled className="form-control" step=""  value={password} onChange={(e)=>setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <p className="fw-bold">QRコード</p>
                                    <div  ref={qrRef}>{qrcode}</div>
                                    <div className="d-flex flex-column justify-content-center pt-4">
                                        <div className="text-center add">
                                            <Button  label="QRコードダウンロード" className="lunch-edit-btn py-2 w-50 " loading={downloading} onClick={downloadQRCode} />
                                        </div>
                                        <div className="text-center add mt-2">
                                            <Button label="QRコードコピー" className="lunch-edit-btn py-2 w-50 " onClick={copyQRCodeToClipboard} />
                                        </div>
                                        <div className="text-center add mt-4">
                                            {/* <span className="fw-bold">フロントURL : </span><Button className="lunch-edit-btn py-2 w-50 text-center " label={Front_url+ "order/" + company_id} /> */}
                                            <Button className="lunch-edit-btn py-2 w-50 text-center " label="注文ページーへ" onClick={gofront} />
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="w-50 p-4">
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="username" className="col-sm-4 col-form-label text-end">ユーザー名</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control"  value={username} onChange={(e)=>setUsername(e.target.value)}  />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="companyname" className="col-sm-4 col-form-label text-end">現場名</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control"  value={companyname} onChange={(e)=>setCompanyname(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="phonenumber" className="col-sm-4 col-form-label text-end">電話番号</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control"  value={phonenumber} onChange={(e)=>setPhonenumber(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="address" className="col-sm-4 col-form-label text-end">現場住所</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" step=""  value={address} onChange={(e)=>setAddress(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="faxcontent" className="col-sm-4 col-form-label text-end">送信内容</label>
                                    <div className="col-sm-8">
                                        <textarea  className="form-control" rows="4"   value={faxcontent} onChange={(e)=>setFaxcontent(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row">
                                    <label htmlFor="faxsendtime" className="col-sm-4 col-form-label text-end">FAX送信時刻設定</label>
                                    <div className="col-sm-8">
                                        <input type="time" className="form-control" step=""  value={faxsendtime} onChange={(e)=>setFaxsendtime(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3 w-100 row text-center d-flex align-items-center">
                                    {/* <label htmlFor="alertimage" className="col-sm-4 col-form-label text-end">アラート画面登録</label>
                                    <div className="col-sm-8">
                                        <input type="file" className="form-control"  onChange={alertImageUpload} accept="img/*" />
                                        {alertimage && <p>Selected file: {alertimage.name}</p>}
                                    </div> */}
                                    <div className="col-sm-4">
                                        <label className="lunch-edit-btn py-2 w-100">
                                            アラート画像登録
                                            <input
                                                type="file"
                                                onChange={alertImageUpload}
                                                accept="img/*"
                                                style={{ display: "none" }}
                                            />
                                            </label>
                                    </div>
                                    
                                        <div className="col-sm-8 text-start">
                                            {alertimage && <p className="m-0">画像名：{alertimage}</p>}
                                        </div>
                                </div>
                            
                            </div>
                            
                            
                </div>
                <div className="d-flex flex-column justify-content-center mt-4 pt-4">
                    <div className="text-center add">
                        <Button label="Fax送信内容を確認" className="lunch-edit-btn py-2 w-25" loading={btnloading} onClick={saveFaxinfo} />
                    </div>
                </div>
                        
                    
            </div>
        </div>
    );
}

export default Setting;