import { Route, Routes, useNavigate } from "react-router-dom";
import { useState } from "react";
import Restaurant from "../Magage/Restaurant";
import FactoryManage from "../Magage/Factorymanage";
import OrderHistory from "../Magage/OrderHistory";
import FaxInfo from "../Magage/FaxInfo";
import RestaurantEdit from "../Magage/Edits/RestaurantEdit";
import FactoryEdit from "../Magage/Edits/FactoryEdit";
import Setting from "./Setting";

function DashboardMain(){
    const [content, setContent] = useState("restaurant");
    const navigate = useNavigate();
    const showContent = (link) => {
        setContent(link);
        console.log(content);
        navigate(link);
    };
    // useEffect(()=>{
    //     showContent("restaurant");
    // },[content])
    return(
        <div>
            <div className="p-4 m-4">
                <div className="w-100 admin-nav row m-0">
                    <div onClick={()=>showContent("restaurant")} className={content === "restaurant" ? "col-sm admin-navbtn bg-primary" : "col-sm admin-navbtn "}>注文先管理</div>
                    <div onClick={()=>showContent("factorymanage")} className={content === "factorymanage" ? "col-sm admin-navbtn bg-primary" : "col-sm admin-navbtn "}>現場管理</div>
                    <div onClick={()=>showContent("orderhistory")} className={content === "orderhistory" ? "col-sm admin-navbtn bg-primary" : "col-sm admin-navbtn "}>注文履歴</div>
                    <div onClick={()=>showContent("faxinfo")} className={content === "faxinfo" ? "col-sm admin-navbtn bg-primary" : "col-sm admin-navbtn "}>送信文章ひな型</div>
                    <div onClick={()=>showContent("myaccount")} className={content === "myaccount" ? "col-sm admin-navbtn bg-primary" : "col-sm admin-navbtn "}>マイアカウント</div>
                </div>
                <div className="content-bg">
                    <Routes>
                        <Route path="/" element={<Restaurant showContent={showContent} />} />
                        <Route exact path="restaurant/" element={<Restaurant showContent={showContent} key={1} />} />
                        <Route path="restaurant/:restaurantid/*" element={<RestaurantEdit showContent={showContent} key={2} />} />
                        <Route path="factorymanage/" element={<FactoryManage showContent={showContent} />} />
                        <Route path="factorymanage/:factoryid/*" element={<FactoryEdit showContent={showContent} />} />
                        <Route path="orderhistory/*" element={<OrderHistory showContent={showContent} />} />
                        <Route path="faxinfo" element={<FaxInfo showContent={showContent} />} />
                        <Route path="myaccount" element={<Setting showContent={showContent} />} />
                    </Routes>
                </div>
            </div>
           
            
        </div>
    );
}

export default DashboardMain;