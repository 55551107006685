import logo from "../../assets/image/logo.svg"
import { createContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AlertWarningEnd from './AlertWarningEnd';
import AlertOrderend from './AlertOrderend';
import { BASE_BACKEND } from "../../App";
import moment from 'moment-timezone';
import { QRCodeCanvas } from "qrcode.react";

 const Orderover = createContext();

function ClientTop(){
    const targetRef = useRef(null);
    const { company } = useParams();
    const location = window.location.href;
    const navigate = useNavigate();    
    const [menus,setMenus] = useState("");
    const [showalert, setShowalert] = useState(false);
    const [showorderend, setShoworderend] = useState(false);
    const [ over, setOver ] = useState(false); 
    const [ orderend ,setOrderend ] = useState("");
    const [ japanTime, setJapanTime] = useState('');
    
    useEffect(()=>{
        
        console.log(process.env);
        axios.get(BASE_BACKEND + "api/" + company + "/lunchmenulists", 
            {
            headers:{
                "Content-Type": "application/json",
                "Accept":"application/json"
            }}).then(response=>{
                let temp_menus = response.data.data;
                console.log(response);
                setMenus(temp_menus);
            }).catch(error=>{
                console.log(error);
            });
        axios.get(BASE_BACKEND + "api/" + company + "/gettime", 
            {
            headers:{
                "Content-Type": "application/json",
                "Accept":"application/json"
            }}).then(res=>{
                console.log(res.data);
                if(res.data){
                    const end = (res.data.faxsend_time.slice(0,2) + res.data.faxsend_time.slice(3,5)) * 1;
                    let alert = end*1 -10; 
                    if(res.data.faxsend_time.slice(3,5) * 1  < 10 ){
                        
                        alert = end*1 - 50;
                    }
                    localStorage.setItem("alert", alert);
                    localStorage.setItem("end", end);
                    
                    setOrderend((res.data.faxsend_time.slice(0,2) + "時" +  res.data.faxsend_time.slice(3,5)) + "分") ;
                    var today = moment().tz('Asia/Tokyo');
                    // alert(today.format('HHmm'));
                    // var hour = ("0" + (today.getHours())).slice(-2);
                    // var minute = ("0" + (today.getMinutes())).slice(-2);
                    var nowtime = today.format('HHmm') * 1;
                    console.log(nowtime, alert, end);
                    localStorage.setItem("orderover", false);
                    if(nowtime >= alert){
                        console.log("alert");
                        if(nowtime >= end){
                            console.log("end");
                            setShoworderend(true);
                            setOver(true);
                            localStorage.setItem("orderover", true);
                            
                        }else{
                            setShowalert(true);
                            localStorage.setItem("orderover", false);
                        }
                    }
                }
                
            }).catch(error=>{
                console.log(error);
            });

            console.log();
            const interval = setInterval(() => {
                var today = moment().tz('Asia/Tokyo');
                var nowtime = today.format('HHmm') * 1;
                const alerttime = localStorage.getItem("alert");
                const orderendtime = localStorage.getItem("end");
                console.log(alerttime, orderendtime);
                
                if(nowtime == alerttime){
                    console.log(nowtime);
                    setShowalert(true);
                    console.log("1");
                }
                if(nowtime == orderendtime){
                    console.log(nowtime);
                    console.log("2");
                    setShoworderend(true);
                    setOver(true);
                    localStorage.setItem("orderover", true);
                    
                }
              }, 60000);
              return () => clearInterval(interval);    
    },[]);
    const viewOrder = (menu_id)=>{
        // alert(menu_id);
        navigate(`menu/${menu_id}/detail`);

    }
    const viewAll = ()=>{
        axios.get(BASE_BACKEND + "api/" + company + "/lunchmenulists/all", 
            {
            headers:{
                "Content-Type": "application/json",
                "Accept":"application/json"
            }}).then(response=>{
                let temp_menus = response.data;
                console.log(temp_menus);
                setMenus(temp_menus);
            }).catch(error=>{
                console.log(error);
            });
    }

    const handleButtonClick = () => {
        targetRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      };
      const closeAlert = (iscolse) =>{
        setShowalert(iscolse);
      }
      const closeOrderend = (iscolse) =>{
        setShoworderend(iscolse);
      }
      const qrcode = (
        <QRCodeCanvas
          id="qrCode"
          value={location}
          size={400}
          bgColor={"#FFD658"}
          level={"H"}
        />);
    return(
        <Orderover.Provider value={over}>
            <div>
                {showalert && <AlertWarningEnd close={closeAlert} />}
                {showorderend && <AlertOrderend close={closeOrderend} />}
                <div className='d-flex is-reverse'>
                    <div className="alert-bg text-center p-2">
                        <p className="text-white m-0">{orderend}までに注文してください。</p>
                    </div>
                    <div className="header-bg">
                        <div className="row m-0 pb-4 align-items-end ">
                            <div className="col-md-6 p-0 text-center position-relative ">
                                <div className='d-flex '>
                                    <img className='w-100 header-image' alt="image for lunch" />
                                </div>
                                <p className="pt-4 m-0 header-font">お弁当注文オーダーシステム</p>
                                <div className="my-4 w-50 mx-auto">
                                    
                                    <div className="">
                                        <img className="image-full" src={logo} alt='header logo'/>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-md-6 p-0">
                                <div className='w-50 mx-auto header-button-padding'>
                                    <button  onClick={handleButtonClick} className="header-button">注文を始める</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='position-relative'>
                    <div className='for-Top position-absolute'   ref={targetRef}>
                        <div className="lunch-box row row-cols-2 m-0">
                            {Array.isArray(menus) && menus.map((menu)=>
                                <div key={menu.id} className='col-lg-3 col-md-4 col-xs-6  p-3 my-4'>
                                <div className='lunch'>
                                    <div className=''>
                                        <img className='image-full lunchmenu-image' src={BASE_BACKEND + "image/" + menu.image_url}  alt={BASE_BACKEND + "image/" + menu.image_url} />
                                    </div>
                                    <div className='py-3'>
                                        <div className='lunch-title-font'>{menu.title}</div>
                                        <div className='d-flex justify-content-end lunch-price-font'>¥{menu.price}</div>
                                    </div>
                                    <button className='order-button' onClick={()=>{viewOrder(menu.id)}}>
                                        注文する
                                    </button>
                                </div>
                            </div>
                            )}
                            
                            
                            
                        </div>
                        <div className="footer-up">
                            <div className='text-center'>
                                <button className='view-all-button' onClick={viewAll}>お弁当一覧を見る</button>
                            </div>
                        </div>

                        <div className="qr-wrap align-items-center mx-auto " id="qrcode">
                            <div className="row">
                                <div className="qr-ex col-lg-6">
                                    <div className="qr-title" >QRコード</div>
                                    <div>スマートフォンの方はQRコードをスキャンして注文してください</div>
                                </div>                                
                                <div className="qrcode col-lg-6">{qrcode}</div>
                            </div>
                            
                        </div>
                        <div className='footer'>
                            © 2023 Questar, All Rights Reserved
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </Orderover.Provider>
        
    );
}

export default ClientTop;