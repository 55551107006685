function Loading() {
    return (
        
        <div className="load-container">
            <div className="load-flex">
                <div className="load-loader">
                </div>
            </div>
            <div className="load-load-text">
                読み込み中...
            </div>
        </div>
    );
}

export default Loading;