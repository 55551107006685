import {  useEffect, useRef, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_BACKEND } from "../../../../App";
import { Button } from 'primereact/button';
import Loading from "../../../Loading";
import { Toast } from "primereact/toast";
import validator from 'validator'

// import { token, header } from "../../AdminDashboard/AdminDashboard";

function ManageRestaurant({setCurrentrestaurant, setIsfocus}){
    const [ isloading, setIsloading ] = useState(true);
    const [ btnloading, setBtnLoading ] = useState(false);
    // const restaurantid = useContext(RestaurantContext);
    const [ restaurant, setRestaurant ] = useState();
    const navigate = useNavigate();
    const { restaurantid }  = useParams();
    
    const [restrantname, setRestaurantname] = useState("");
    const [faxnumber, setFaxnumber] = useState("");
    const [mailaddress, setMailaddress] = useState("");
    const toast = useRef(null);
    var message = {};
    const show = (message) => {
        toast.current.show({ severity: message.severity, summary: message.summary, detail: message.message});
    };
    useEffect(()=>{
        setIsfocus("res");
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        if(restaurantid!=="new"){
            const url = BASE_BACKEND + "api/bentos/" + restaurantid;
            axios.get(url,header)
            .then(res=>{
                console.log(res.data.data);
                if(res.data.data){
                    setRestaurant(res.data.data);
                    setRestaurantname(res.data.data.name);
                    setFaxnumber(res.data.data.fax_number);
                    setMailaddress(res.data.data.mail_address);
                    setIsloading(false);
                }
                
            }).catch(err=>console.log(err.response.data));
        }else{
            setIsloading(false);
        }
        
    },[restaurantid]);

    
    const regex_faxnumber = /^(\+)?(((((\d+)|(\(\d+\))|(\(\d+\s(\d)\)))(\s|-|\d+))+)|((\d+)|(\(\d+\))$)+)+\d$/gm;
    const regex_mail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const saveRestaurant = () =>{
        setBtnLoading(true);
        const token = localStorage.getItem("token");
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const data = {
            "name": restrantname,
            "fax_number" : faxnumber,
            "mail_address" : mailaddress
        };
        
        if(!data.name){
            message = {
                severity: 'error', 
                summary: '注文先名を入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            
            setBtnLoading(false);
        }else if(!data.fax_number || regex_faxnumber.test(data.fax_number) === false){
            message = {
                severity: 'error', 
                summary: 'FAX番号を正確に入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else if(data.mail_address && regex_mail.test(data.mail_address) === false){
            
            message = {
                severity: 'error', 
                summary: 'メールアドレスを正確に入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            setBtnLoading(false);
        }else{
            if(data.fax_number.slice(0,3) !== "+81"){
                data.fax_number = "+81" + data.fax_number.slice(1);
            }
            console.log(data.fax_number);
                if(restaurantid === "new"){
                    const url1 = BASE_BACKEND + "api/bentos";
                    console.log(data);
                    axios.post(url1,data,header)
                    .then((res)=>{
                        setCurrentrestaurant(res.data.data.id);
                        setBtnLoading(false);
                        navigate('managelunch');
                        console.log(res);
                    }).catch(err=>{
                        console.log(err);
                        setBtnLoading(false);
                    });
                }else{
                    const url = BASE_BACKEND + "api/bentos/";
                    axios.put(url + restaurantid,data,header)
                    .then((res)=>{
                        console.log(res);
                        setBtnLoading(false);
                        navigate('managelunch');
                    }).catch(err=>{
                        console.log(err.response.data);
                        setBtnLoading(false);
                    });
                }
            
            
            
        }
        
    }
    return (
        <div className="d-flex flex-column justify-content-center ">
            <Toast ref={toast} />
            {isloading && <Loading />}
                        <div className="mb-3 w-75 row">
                            <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">注文先名</label>
                            <div className="col-sm-6">
                                <input type="text" placeholder="注文先名を入力してください" className="form-control"  value={restrantname} onChange={(e)=>setRestaurantname(e.target.value)} />
                            </div>
                        </div>
                        <div className="mb-3 w-75 row">
                            <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">FAX番号</label>
                            <div className="col-sm-6">
                                <input type="text" placeholder="半角数字、ハイフンなしで入力してください。"   className="form-control"  value={faxnumber} onChange={(e)=>setFaxnumber(e.target.value)} />
                            </div>
                        </div>
                        <div className="mb-3 w-75 row">
                            <label htmlFor="staticEmail" className="col-sm-6 col-form-label text-end">メールアドレス</label>
                            <div className="col-sm-6">
                                <input type="text" placeholder="例：***@***.com"  className="form-control"  value={mailaddress} onChange={(e)=>setMailaddress(e.target.value)} />
                            </div>
                        </div>
                        <div className="text-center add">
                                <Button label="保存する" className="lunch-edit-btn py-2 w-25" loading={btnloading} onClick={saveRestaurant} />
                        </div>
                    </div>
    );
}
export default ManageRestaurant;