// import ModeToggler from './ModeToggler';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import "./Admin.css";
import "./Loading.css";
import Login from './component/admin/Auth/Login';
import ClientTop from './component/client/ClientTop';
import ClientUnder from './component/client/ClientUnder';
import ClientUnderLunchDetail from './component/client/ClientUnder/ClientUnderLunchDetail';
import ClientUnderLunchOrderConfirm from './component/client/ClientUnder/ClientUnderLunchOrderConfirm';
import AdminDashboard from './component/admin/AdminDashboard/AdminDashboard';
import withAuth from './component/admin/Auth/WithAuth';
import Pdf from './component/Pdf';
export const Front_url = "https://bentofax.com/";
export let BASE_BACKEND = "http://localhost:8000/";
if (process.env.NODE_ENV === 'development') {
  BASE_BACKEND = "http://localhost:8000/";
}else{
  BASE_BACKEND = "https://xs341585.xsrv.jp/";
}

const ProtectedDashboard = withAuth(AdminDashboard);

function App() {
  
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          {/* for superadmin && admin url */}
          <Route path="/*" Component={Login} />
          <Route path="/admin/*" element={<ProtectedDashboard />} /> 
          <Route path="/super/*" element={<ProtectedDashboard />} /> 

          {/* for client order url */}
          <Route path="/order/:company" Component={ClientTop} />
              <Route path='/order/:company/menu/:lunch_id' Component={ClientUnder} >
                  <Route path='detail' Component={ClientUnderLunchDetail} />
                  <Route path='detail/confirm' Component={ClientUnderLunchOrderConfirm} />
              </Route>

          <Route path="/pdfupload" element={<Pdf />} /> 


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
