import logo from "../../../assets/image/logo.svg"
import vector from "../../../assets/image/Vector-header.png"

function ClientUnderHeader(){
    return (
        <div>
            <div className="under-header position-relative">
                <img className="under-logo position-absolute" src={logo} alt="" />
                <img className="under-vector" src={vector} alt="" />
            </div>
        </div>       
    );
}

export default ClientUnderHeader;