import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import ManageRestaurant from "./ManageRestaurant";
import ManageLunch from "./ManageLunch";
import { createContext, useContext, useEffect, useState } from "react";

export const RestaurantContext = createContext();

function RestaurantEdit(){
    const [isfocus, setIsfocus] = useState("res");
    const [currentrestaurantid, setCurrentrestaurantid] = useState();
    const {  restaurantid }  = useParams();
    const focus = (val) =>{
        setIsfocus(val);
    }
    const set = (val)=>{
        setCurrentrestaurantid(val);
        console.log(val);
    }
    const location = useLocation();
    useEffect(()=>{
        setCurrentrestaurantid(restaurantid);
    },[]);
    
    
    
    return(
        <div>
            <div>
                <div className="d-flex justify-content-center">
                    <Link to="managerestaurant"  onClick={()=>setIsfocus("res")} className={isfocus === "res" ? "switch-title switch-border" : "switch-title"}>注文設定</Link>
                    <Link to="managerestaurant/managelunch" onClick={()=>setIsfocus("lun")} className={isfocus === "lun" ? "switch-title switch-border" : "switch-title"}>メニュー管理</Link>
                </div>
            </div>
            <div className="position-relative">
                <div className="white-box set-height overflow-auto py-4">
                        <Routes>
                            <Route path="managerestaurant" element={<ManageRestaurant setCurrentrestaurant={set} setIsfocus={focus} />} />
                            <Route path="/managerestaurant/managelunch" element={<ManageLunch currentrestaurantid={currentrestaurantid} setIsfocus={focus} />}  />
                        </Routes>
                </div>
            </div>
        </div>
    );
}
export default RestaurantEdit;