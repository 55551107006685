import headlogo from "../../../assets/image/admin/header-logo.svg"
import avatar from "../../../assets/image/admin/admin.png"
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

function AdminHeader({username,supertoken}){
    const navigate = useNavigate();
    // const userSetting = ()=>{
    //     if(localStorage.getItem("usertype") == "normaladmin"){
            
    //         navigate("setting");
    //     }
        
    // }
    const logout = () =>{
        localStorage.clear();
        navigate("/");
    }
    return(
        <div className="admin-header d-flex justify-content-between align-items-center">
            <div className="">
                <img src={headlogo}  alt="asd" />
            </div>
            <div   className="usericon mx-4 d-flex">
                {supertoken && 
                <div  className=" mx-4 d-flex">
                    <button onClick={()=>{navigate("/super");localStorage.removeItem("token");localStorage.setItem("usertype","superadmin")}} className="super-btn">スーバーアドミンページーへ</button>
                </div>
                }
                <div  className=" mx-4 d-flex">
                    <div className="avatar-img">
                        <img className="rounded-circle w-100" src={avatar} alt="avatar" />
                    </div>
                    <div className="text-white mx-4">
                        <p className="mb-1 fw-bolder">{username}</p>
                        <p className="m-0">担当者</p>
                    </div>
                </div>
                <div onClick={logout}>
                    <h1><i className="bi bi-box-arrow-right text-white"></i></h1>
                </div>
            </div>
        </div>
    );
}

export default AdminHeader;