import { useState, useEffect, useRef } from "react";

import axios from "axios";
import { BASE_BACKEND } from "../../../../App";
import { Button } from 'primereact/button';
import { Toast } from "primereact/toast";

function NameInput({onCloseModal, factoryid}){
    const [ btnloading, setBtnLoading ] = useState(false);
    const [name, setName] = useState("");
    const toast = useRef(null);
    var message = {};
    const show = (message) => {
        toast.current.show({ severity: message.severity, summary: message.summary, detail: message.message});
    };
    

    const savename = () =>{
        setBtnLoading(true);
        const token = localStorage.getItem("token");
        
        const header = {
            headers:{
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
        const data = {
            "name": name,
            "subcompany_id": factoryid
        };
        if(data.name === ""){
            message = {
                severity: 'error', 
                summary: 'お名前を入力してください。',
                message:"もう一度入力してください。"};
            show(message);
            
            setBtnLoading(false);
        }else{
            const url = BASE_BACKEND + "api/clientusermanage";
                axios.post(url,data,header)
                    .then((res)=>{
                        console.log(res.data);
                        onCloseModal(false);
                        setBtnLoading(false);
                    }).catch(err=>console.log(err.response.data));
        }
            
        
    }
    const closeModal = () =>{
        onCloseModal(false);
    }
    return (
        <div className="admin-modal-back">
        <Toast ref={toast} />
            <div className="admin-modal name-modal">
                    <div className="d-flex justify-content-center">
                        <div className="switch-title switch-border m-4">職員の新規追加</div>
                    </div>
                    <div className="row mt-2 d-flex justify-content-center">
                        <div className="mb-4 col-md-8">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" placeholder="職員名を入力してください"  value={name} onChange={(e)=>setName(e.target.value)} />
                                </div>
                        </div>
                        <div className=" add">
                            <Button label="登録" className="lunch-edit-btn py-2 w-25" loading={btnloading} onClick={savename} />
                        </div>
                        <div className="mt-3">
                            <button onClick={closeModal} className="cancel-btn"><i className="bi bi-x-lg"></i> キャンセル</button>
                        </div>
                </div>
            </div>
        </div>
    );
}
export default NameInput;