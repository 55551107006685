import { Route, Routes, useNavigate } from "react-router-dom";
import Superadmin from "./Superadmin";
import Edituser from "./Edituser";

function Superwrap(){
    
    const navigate = useNavigate();
    const setUser_idhandle = (link) => {
        navigate(link);
    };
    // useEffect(()=>{
    //     showContent("restaurant");
    // },[content])
    return(
        <div>
            <div className="p-4 m-4">
                <div className="content-bg">
                    <Routes>
                        <Route exact path="/" element={<Superadmin setUser_idhandle={setUser_idhandle} />} />
                        <Route path=":user_id" element={<Edituser />} />
                        
                    </Routes>
                
                </div>
            </div>
           
            
        </div>
    );
}

export default Superwrap;