import {  useParams } from "react-router-dom";
import { BASE_BACKEND } from "../../App";
import axios from "axios";
import { useEffect, useState } from "react";

function AlertOrderend({close}){
    const { company } = useParams("");
    const [ alertimage, setAlertimage ] = useState(null);
    const closeModal = () =>{
        close(false);
    }
    
    useEffect(()=>{
        const url = BASE_BACKEND + "api/" + company + "/getalertimage";
        const header = {
            headers:{
                "Content-Type": "application/json",
                "Accept":"application/json"
            }
        }
            axios.get(url,header)
                .then((res)=>{
                    console.log(res.data);
                    setAlertimage(res.data.alert_image);
                        
                }).catch(err=>console.log(err));
    },[])
    return(
        <div className="client-alert-back">
            <div className="client-alert">
                <div className="alert-title">本日の注文は締め切りました</div>
                <div className="alert-body">
                {alertimage == null ? <div className="alert-detail">
                        <span>申し訳ございません。</span><br />
                        <span>お弁当の注文締め切り時刻を過ぎているため、</span><br />
                        <span>注文を確定することができません。</span><br />
                    </div>: 
                    <div className=''>
                        <img className='image-full lunchmenu-image' src={BASE_BACKEND + "alertimages/" + alertimage}  alt={BASE_BACKEND + "alertimages/" + alertimage} />
                    </div>}
                </div> 
                <div>
                    <button className="back-button" onClick={closeModal}>トップへ戻る</button>
                </div>
            </div>
        </div>
    )
}

export default AlertOrderend;